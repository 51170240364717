body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tk-loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tk-loading::after {
  content: "";
  background: transparent url(https://tk-assets.sfo2.digitaloceanspaces.com/home/trophy-king.svg) no-repeat center center;
}

@media only screen and (max-width: 320px) {
  body #root * {
    display: none!important;
  }

  body #root #ViewportWarning {
    display: block!important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  body #root #ViewportWarning::after {
    background: transparent url(https://tk-assets.sfo2.digitaloceanspaces.com/home/trophy-king.svg) no-repeat 90% 45%;
    background-size: 15%;
    border-left: 8px solid;
    content: "Please use a larger resolution display.";
    color: #0201bf;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    text-shadow: 0px 1px 1px rgba(36, 36, 36, 0.25);
    font-weight: 900;
    font-size: 48px;
    vertical-align: middle;
    padding: 5% 1% 0 50px;
    box-sizing: border-box;
    line-height: 54px;
  }
}
