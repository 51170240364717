@font-face {
    font-family: "play24";
    src: url("./font/play24.eot?52051241");
    src: url("./font/play24.eot?52051241#iefix") format("embedded-opentype"),
    url("./font/play24.woff2?52051241") format("woff2"),
    url("./font/play24.woff?52051241") format("woff"),
    url("./font/play24.ttf?52051241") format("truetype"),
    url("./font/play24.svg?52051241#play24") format("svg");
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'play24';
    src: url('./font/play24.svg?52051241#play24') format('svg');
  }
}
*/

/*[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "play24", serif;
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    !* opacity: .8; *!

    !* For safety - reset parent styles, that can break glyph codes*!
    font-variant: normal;
    text-transform: none;

    !* fix buttons height, for twitter bootstrap *!
    line-height: 1em;

    !* Animation center compensation - margins should be symmetric *!
    !* remove if not needed *!
    margin-left: 0.2em;

    !* you can be more comfortable with increased icons size *!
    !* font-size: 120%; *!

    !* Font smoothing. That was taken from TWBS *!
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    !* Uncomment for 3D effect *!
    !* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); *!
}*/

.icon-play24-logo:before {
    content: '\e800';
}

.play24-icon {
    display: inline;
    font-family: "play24", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    vertical-align: middle;
    speak: none;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 0 2px 1.5px #0a040345;
}

/* '' */